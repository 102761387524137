.container {
  display: flex;
  align-items: center;
  gap: 14px;
}

.categories {
  width: 250px;
}

.statuses {
  width: 250px;
}

.disabled-systems {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--color-white);
  min-height: 38px;
  padding: 0px 12px;
  border: 1px solid var(--color-gray-30);
  border-radius: var(--border-radius);
}

.amount-label {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-gray-30);
  color: var(--color-gray-80);
  height: 20px;
  padding: 0 6px;
  border-radius: 320px;
}

.create-button-container {
  margin-left: auto;
}
