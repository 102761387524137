.time {
  color: var(--color-gray-70);
}

.table :global(.ant-table-selection-column) {
  min-width: 200px;
}

.buttons {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  width: 450px;
}