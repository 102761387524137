.external {
  text-align: center;
  max-width: 60px;
}

.status {
  display: flex;
  align-items: center;
  gap: 20px;
}

.button {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.label {
  height: 20px;
  min-width: 20px;
  border-radius: 320px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-80);
  color: var(--color-white);
}

.product-image-container {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border: 1px solid var(--color-gray-20);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-name-cell {
  display: flex;
  align-items: center;
  gap: 16px;
}

.product-image-container img {
  max-width: 80%;
  max-height: 80%;
}